import React, { useContext } from 'react';
import { string } from 'prop-types';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';

function WeddingInfoBox({ title, date, time, description }) {
  const { lang } = useContext(LangContext);
  return (
    <div className="col-md-6 col-sm-6 text-center">
      <div className="event-wrap">
        <h3>{title}</h3>
        <div className="event-col">
          <i className="icon-clock"></i>
          <span>{time}</span>
        </div>
        <div className="event-col">
          <i className="icon-calendar"></i>
          <span>{date}</span>
        </div>
        {description && (
          <>
            <div className="event-col">
              <i className="icon-location-pin"></i>
              <span dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <div className="event-col">
              <span>
                <a
                  // href="https://www.google.com/maps/dir/4.4761088,114.0129792/borneo+tree+house/@5.2690418,113.9705884,8z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x323b1332df483c49:0x126be73334f4d727!2m2!1d116.167974!2d6.0641369"
                  href="https://www.google.com/maps/dir//Borneo+Tree+House,+Jalan+Kampung+Tebobon,+Kampung+Ratau,+88450+Kota+Kinabalu,+Sabah/@5.2690418,113.9705884,8z/data=!4m8!4m7!1m0!1m5!1m1!1s0x323b1332df483c49:0x126be73334f4d727!2m2!1d116.167974!2d6.0641369?entry=ttu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-secondary btn-sm">{translation['wedding_get_direction'][lang]}</button>
                  <span style={{ fontSize: '10px' }}>{translation['wedding_get_direction_note'][lang]}</span>
                </a>
              </span>
            </div>
          </>
        )}
        <div className="event-col">
          <i className="icon-info"></i>
          <span>
            <strong>{translation['wedding_dress_code'][lang]}:</strong> Semi-Formal
            <ul>
              <li>{translation['wedding_groom_team'][lang]}: Light Blue</li>
              <li>{translation['wedding_bride_team'][lang]}: Light Pink</li>
            </ul>
            <strong>SOP:</strong> {translation['wedding_sop'][lang]}
          </span>
        </div>
      </div>
    </div>
  );
}

WeddingInfoBox.propTypes = {
  title: string.isRequired,
  date: string.isRequired,
  time: string.isRequired,
  description: string.isRequired,
};

export default React.memo(WeddingInfoBox);
