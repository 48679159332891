import React, { useContext } from 'react';
import { bilingualStories } from './stories-data';
import StoryItem from './StoryItem';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';

function StorySection() {
  const { lang } = useContext(LangContext);

  const renderBilingualStories = () => {
    if (lang === 'en') {
      return bilingualStories.map((s, index) => <StoryItem key={index} {...s.en} isInverted={index % 2 === 1} />);
    }

    if (lang === 'my') {
      return bilingualStories.map((s, index) => <StoryItem key={index} {...s.my} isInverted={index % 2 === 1} />);
    }
  };

  return (
    <>
      <div id="fh5co-couple-story">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading animate-box">
              <h2 className="main-font">{translation['story_title'][lang]}</h2>
              <p className="sub-title">{translation['story_subtitle'][lang]}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-md-offset-0">
              <ul className="timeline animate-box">{renderBilingualStories()}</ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StorySection;
