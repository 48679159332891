import WeddingImg from '@assets/images/wedding-logo.png';
import React, { useState, useContext } from 'react';
import CountContainer from './CountContainer';
import ScrollToDown from './ScrollToDown';
import { styBackground, styHero, styWrapper } from './styles';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';

const DELAY_TIME = 1500;

function WelcomeSection({ onClickDetail }) {
  const [loading, setLoading] = useState(false);
  const [alreadyDownloadData, setAlreadyDownloadData] = useState(false);
  const { lang } = useContext(LangContext);

  const handleScrollTo = () => {
    const element = document.getElementById('fh5co-couple');
    element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  const handleShowDetail = () => {
    if (loading) return undefined;

    try {
      const myAudio = document.getElementById('myAudio');
      myAudio.play();
    } catch {
      console.error('FAILED_TO_PLAY_MUSIC');
    }

    onClickDetail();

    if (!alreadyDownloadData) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        setAlreadyDownloadData(true);
        handleScrollTo();
      }, DELAY_TIME);
    } else {
      handleScrollTo();
    }
  };

  return (
    <div css={styHero}>
      <header
        id="fh5co-header"
        role="banner"
        className="fh5co-cover"
        css={styBackground}
        data-stellar-background-ratio="0.5"
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row" css={styWrapper}>
            <div className="col-md-8 col-md-offset-2 text-center">
              <img src={WeddingImg} alt="wedding-dinda-indra" />
              <h4 className="sub-title">{translation['wedding'][lang]}</h4>
              <h1 className="title">Nadia &amp; Adri</h1>
              <div className={'margin__bottom'}>
                <CountContainer />
              </div>
            </div>
          </div>
          <div className="row">
            <ScrollToDown loading={loading} onClick={handleShowDetail} />
          </div>
        </div>
      </header>
    </div>
  );
}

export default WelcomeSection;
