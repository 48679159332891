// TODO add image for each timeline
export const bilingualStories = [
  {
    en: {
      title: 'The genesis',
      date: `Somewhere in 07's`,
      description: `Adri and Nadia first met at the NSC Milo Junior Circuit bowling tournament. They caught each other's gaze and smiled, but were too shy to talk to each other. Little did they know, that brief moment would be the start of a beautiful love story.`,
      image: `https://cdn.pixabay.com/photo/2017/06/06/14/58/galaxy-2377456_960_720.jpg`,
    },
    my: {
      title: 'Detik permulaan',
      date: `Sekitar tahun 07's`,
      description: ` Adri dan Nadia bertemu untuk kali pertama semasa Kejohanan Bowling Kecil Milo Junior NSC. Mereka berpandangan dan tersenyum satu sama lain, tetapi terlalu pemalu untuk berbual. Tanpa mereka sedari, momen singkat itu menjadi permulaan kisah cinta yang indah.`,
      image: `https://cdn.pixabay.com/photo/2017/06/06/14/58/galaxy-2377456_960_720.jpg`,
    },
  },
  {
    en: {
      title: 'The monkey love',
      date: `Somewhere in 10's`,
      description: `Adri and Nadia started to talk more and exchanged BBM pins to text each other. They were like two monkeys, swinging from conversation to conversation, enjoying each other's company and couldn't get enough of each other.`,
      image: `https://cdn.pixabay.com/photo/2021/07/09/15/43/monkey-6399443_960_720.png`,
    },
    my: {
      title: 'Cinta monyet',
      date: `Sekitar tahun 10's`,
      description: `Adri dan Nadia mula berbual lebih kerap dan bertukar nombor BBM untuk berhubung. Mereka seperti dua monyet, bermain dalam percakapan yang tidak pernah berhenti, menikmati kehadiran satu sama lain dan tidak dapat cukup dengannya.`,
      image: `https://cdn.pixabay.com/photo/2021/07/09/15/43/monkey-6399443_960_720.png`,
    },
  },
  {
    en: {
      title: 'Lost but close',
      date: `Somewhere in 17's`,
      description: `After years of not talking or meeting, Adri and Nadia reconnected on Twitter when they wished each other Eid Aidilfitri. They were lost but close, and their friendship quickly rekindled.`,
      image: `https://cdn.pixabay.com/photo/2016/02/18/07/11/social-1206612_960_720.png`,
    },
    my: {
      title: 'Hilang tapi dekat',
      date: `Sekitar tahun 17's`,
      description: `Setelah bertahun-tahun tidak berbual atau bertemu, Adri dan Nadia berhubung semula di Twitter ketika mereka mengucapkan Selamat Hari Raya Aidilfitri. Walaupun tersesat jauh, persahabatan mereka cepat berkobar semula.`,
      image: `https://cdn.pixabay.com/photo/2016/02/18/07/11/social-1206612_960_720.png`,
    },
  },
  {
    en: {
      title: 'Strikes again',
      date: `Somewhere in 19's`,
      description:
        'Adri and Nadia met again after a long time and reignited their childhood love. They felt a strong connection and knew that they were meant to be together.',
      image: `https://cdn.pixabay.com/photo/2018/05/24/23/04/bowling-3427969_960_720.png`,
    },
    my: {
      title: 'Bersama semula',
      date: `Sekitar tahun 19's`,
      description: `Adri dan Nadia bertemu semula setelah sekian lama dan membangkitkan kembali cinta kanak-kanak mereka. Mereka merasakan ikatan yang kuat dan tahu bahawa mereka ditakdirkan untuk bersama.`,
      image: `https://cdn.pixabay.com/photo/2018/05/24/23/04/bowling-3427969_960_720.png`,
    },
  },
  {
    en: {
      title: 'Love-Remotely',
      date: '16th August 2020',
      description: `Adri and Nadia decided to take their relationship to the next level, despite being physically apart due to the pandemic. They became an official couple, embarking on a journey of love-remotely. They knew that distance couldn't separate them, and they remained dedicated to each other, finding ways to stay connected despite the challenges.`,
      image: `https://cdn.pixabay.com/photo/2017/02/10/18/47/love-2055960_1280.jpg`,
    },
    my: {
      title: 'Cinta Jarak Jauh',
      date: '16 Ogos 2020',
      description: `Adri dan Nadia memutuskan untuk melangkah ke peringkat seterusnya dalam hubungan mereka, walaupun berjauhan kerana pandemik. Mereka menjadi pasangan rasmi, memulakan perjalanan cinta jarak jauh. Mereka tahu bahawa jarak tidak dapat memisahkan mereka dan mereka kekal setia satu sama lain, mencari cara untuk tetap berhubung walaupun menghadapi cabaran.`,
      image: `https://cdn.pixabay.com/photo/2017/02/10/18/47/love-2055960_1280.jpg`,
    },
  },
  {
    en: {
      title: '4UofSNAS',
      date: '11 November 2022',
      description:
        'This is the day where Adri and Nadia decided to take their relationship to the next level. The unexpected, unfolds, unintended, and unusual love story of Adri and Nadia will be sealed with a promise of love, loyalty, and commitment.',
      image: `https://cdn.pixabay.com/photo/2018/08/03/04/36/couple-3581038_1280.jpg`,
    },
    my: {
      title: '4UofSNAS',
      date: '11 November 2022',
      description: `
      Ini adalah hari di mana Adri dan Nadia memutuskan untuk mengambil hubungan mereka ke tahap seterusnya. Kisah cinta yang tidak terduga, terbuka, tidak disengaja, dan tidak biasa Adri dan Nadia akan disegel dengan janji cinta, kesetiaan, dan komitmen.`,
      image: `https://cdn.pixabay.com/photo/2018/08/03/04/36/couple-3581038_1280.jpg`,
    },
  },
];
