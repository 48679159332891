import React, { useContext } from 'react';
import IconIg from './assets/instagram.png';
import { styButtonWrapper } from './styles';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';

const IG_URL = 'https://www.instagram.com/tgcollective777';
const IG_NAME = '@tgcollective777';

function ButtonLive() {
  const { lang } = useContext(LangContext);
  return (
    <div className="row">
      <div className="col-md-12">
        <p className="text__live">{translation['wedding_live'][lang]}:</p>
        <a href={IG_URL} target="_blank" rel="noreferrer">
          <div css={styButtonWrapper}>
            <div className="img__wrapper">
              <img src={IconIg} alt="Live IG" />
              <span>{IG_NAME}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default React.memo(ButtonLive);
