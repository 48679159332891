import React, { useContext } from 'react';
import useDateCountdown from '@/hooks/useDateCountdown';
import CountItem from './CountItem';
import ButtonLive from '../WeddingSection/ButtonLive';
import { styMargin } from './styles';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';

function CountContainer() {
  const { days, hours, minutes, seconds, timeHasRunOut, isEventOver } = useDateCountdown();
  const { lang } = useContext(LangContext);
  const finalText = isEventOver ? `${translation['is_over'][lang]}` : `${translation['is_live'][lang]}`;

  if (timeHasRunOut)
    return (
      <>
        <div className="row">
          <div className="col-md-12" style={{ fontSize: '20px' }}>
            {finalText}
          </div>
        </div>
        <ButtonLive />
      </>
    );

  return (
    <div className="col-md-12" css={styMargin('0 0 16px 0')}>
      <CountItem text={translation['days'][lang]} number={days} />
      <CountItem text={translation['hours'][lang]} number={hours} />
      <CountItem text={translation['minutes'][lang]} number={minutes} />
      {/* <CountItem text={translation['seconds'][lang]} number={seconds} /> */}
    </div>
  );
}

export default CountContainer;
