import { bool } from 'prop-types';
import React, { useContext } from 'react';
import ButtonLive from './ButtonLive';
import { styWrapper } from './styles';
import WeddingInfoBox from './WeddingInfoBox';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';
import FlowEvents from './FlowEvents';

function WeddingSection() {
  const { lang } = useContext(LangContext);

  return (
    <>
      <div id="fh5co-event" css={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <span className="bismillah">بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</span>
              <h2 className="main-font main-font__wedding">{translation['wedding_title'][lang]}</h2>
              <span className="sub-title sub-title__wedding">{translation['wedding_info'][lang]}:</span>
            </div>
          </div>
          <div className="row">
            <ButtonLive />
            <br />
            <div className="col-md-10 col-md-offset-1">
              <WeddingInfoBox
                title={translation['reception'][lang]}
                time="1:00PM - 5:00PM"
                date={translation['wedding_date'][lang]}
                description="Borneo Tree House, Tebobon <br/> Kota Kinabalu, Sabah"
              />
              <FlowEvents />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

WeddingSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(WeddingSection);
