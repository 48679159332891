// create a translation.js file where I can call translation.welcome[lang] from any component

export const translation = {
  wedding: {
    en: 'Walimatul Urus',
    my: 'Walimatul Urus',
  },
  reception: {
    en: 'Reception',
    my: 'Resepsi',
  },
  days: {
    en: 'Days',
    my: 'Hari',
  },
  hours: {
    en: 'Hours',
    my: 'Jam',
  },
  minutes: {
    en: 'Minutes',
    my: 'Minit',
  },
  seconds: {
    en: 'Seconds',
    my: 'Saat',
  },
  is_live: {
    en: `Event is live`,
    my: `Acara sedang berlangsung`,
  },
  is_over: {
    en: `Event is over`,
    my: `Acara sudah berlangsung`,
  },
  detail: {
    en: 'Detail',
    my: 'Maklumat',
  },
  story: {
    en: 'Our Story',
    my: 'Kisah Kami',
  },
  info: {
    en: `By the mercy of Allah,accompanied by the intention to carry out the Sunnah Rasulullah ﷺ to form a
    Sakinah, Mawaddah wa Rahmah household, we seek for your do'a so that our marriage will be running
    smoothly and blessed.`,
    my: `Dengan rahmat Allah, diiringi dengan niat untuk menjalankan Sunnah Rasulullah ﷺ untuk membentuk rumah tangga Sakinah, Mawaddah wa Rahmah, kami memohon doa dari anda agar perkahwinan kami dapat berjalan lancar dan diberkati.`,
  },
  desc_bride: {
    en: `The Princess of`,
    my: `Puteri kepada`,
  },
  desc_groom: {
    en: `The Prince of`,
    my: `Putera kepada`,
  },
  wedding_date: {
    en: `Friday, 16 June 2023`,
    my: `Jumaat, 16 Jun 2023`,
  },
  wedding_title: {
    en: `Wedding Ceremony`,
    my: `Majlis Perkahwinan`,
  },
  wedding_info: {
    en: `Insha Allah, the ceremony will be held on`,
    my: `Insha Allah, majlis akan diadakan pada`,
  },
  wedding_get_direction: {
    en: `Get Direction`,
    my: `Dapatkan Arah`,
  },
  wedding_get_direction_note: {
    en: `p/s: We will be using Google Maps to guide you to the venue`,
    my: `p/s: Kami akan menggunakan Google Maps untuk membimbing anda ke lokasi.`,
  },
  wedding_groom_team: {
    en: `Groom's Team`,
    my: `Team Pengantin Lelaki`,
  },
  wedding_bride_team: {
    en: `Bride's Team`,
    my: `Team Pengantin Perempuan`,
  },
  wedding_sop: {
    en: `Please keep your mask on at all times and maintain social distancing.`,
    my: `Sila pakai topeng pada setiap masa dan pastikan jarak sosial dijaga.`,
  },
  wedding_dress_code: {
    en: `Dress Code`,
    my: `Pakaian`,
  },
  wedding_live: {
    en: `Live session via Instagram`,
    my: `Sesi langsung melalui Instagram`,
  },
  flow_event_title: {
    en: `Flow of Events`,
    my: `Susunan Acara`,
  },
  flow_event_1: {
    en: `Arrival of invited guests`,
    my: `Ketibaan tetamu jemputan`,
  },
  flow_event_2: {
    en: `Arrival of the bride and groom`,
    my: `Ketibaan rombongan pengantin`,
  },
  flow_event_3: {
    en: `Recitation of doa and sprinkling session`,
    my: `Bacaan doa dan sesi merenjis`,
  },
  flow_event_4: {
    en: `Feast, music, karaoke etc`,
    my: `Jamuan, musik, karaoke dll`,
  },
  flow_event_5: {
    en: `Photo session and closing`,
    my: `Sesi bergambar dan bersurai`,
  },
  story_title: {
    en: `Journey of SNAS`,
    my: `Perjalanan SNAS`,
  },
  story_subtitle: {
    en: `Love Story of Nadia and Adri`,
    my: `Kisah Cinta Nadia dan Adri`,
  },
  wedding_quote: {
    en: `“Indeed in that are signs for a people who give thought. And among His Signs is this, that He created for you mates from among yourselves, that ye may dwell in tranquillity with them, and He has put love and mercy between your (hearts): verily in that are Signs for those who reflect.”`,
    my: `“Dan di antara tanda-tanda yang membuktikan kekuasaannya dan rahmatNya, bahawa Ia menciptakan untuk kamu (wahai kaum lelaki), isteri-isteri dari jenis kamu sendiri, supaya kamu bersenang hati dan hidup mesra dengannya, dan dijadikanNya di antara kamu (suami isteri) perasaan kasih sayang dan belas kasihan.”`,
  },
  rsvp_intro: {
    en: `Dear valued guests`,
    my: `Para tetamu yang dihormati`,
  },
  rsvp_info_1: {
    en: `We are thrilled to invite you to our wedding event. As we prepare for this special occasion, we
    kindly request that you RSVP before May 31st, 2023, if possible.`,
    my: `Kami dengan sukacitanya menjemput anda untuk menghadiri majlis perkahwinan kami. Sebagai
    persiapan untuk majlis ini, kami memohon agar anda dapat mengesahkan kehadiran anda sebelum 31 Mei 2023.`,
  },
  rsvp_info_2: {
    en: `By RSVPing early, you help us to avoid any last-minute complications or disappointments. We
    understand that unforeseen circumstances can arise, but we kindly ask that you let us know if you
    will be unable to attend as soon as possible.`,
    my: `Dengan mengesahkan kehadiran anda awal, anda dapat membantu kami mengelakkan sebarang
    kejadian yang tidak diingini. Kami faham bahawa keadaan yang tidak dijangka boleh berlaku, tetapi
    kami memohon agar anda dapat memberitahu kami sekiranya anda tidak dapat menghadiri majlis kami.`,
  },
  rsvp_info_3: {
    en: `To RSVP, simply tap on the button below and fill out the google form.`,
    my: `Untuk mengesahkan kehadiran anda, sila klik butang di bawah dan isi borang google.`,
  },
  rsvp_info_4: {
    en: `We appreciate your consideration and cooperation in this matter. Your RSVP helps us to ensure that
    we can provide you with the best possible experience. We look forward to seeing you at the event and
    celebrating together. Thank you.`,
    my: `Kami menghargai kerjasama anda dalam perkara ini. Mengesahkan kehadiran anda membantu kami
    memastikan bahawa kami dapat menyediakan anda dengan pengalaman terbaik. Kami berharap dapat
    bertemu dengan anda di majlis perkahwinan kami. Terima kasih.`,
  },
  regards: {
    en: `Best Regards`,
    my: `Salam Hormat`,
  },
  rsvp_title: {
    en: `RSVP`,
    my: `Mengesahkan Kehadiran`,
  },
  rsvp_button: {
    en: `RSVP here`,
    my: `Mengesahkan kehadiran di sini`,
  },
  rsvp_ended: {
    en: `RSVP has ended`,
    my: `Masa untuk mengesahkan kehadiran telah berakhir`,
  },
  gift_list_title: {
    en: `Gift List`,
    my: `Senarai Hadiah`,
  },
  gift_list_info: {
    en: `Having said that, your presence on the day really is the most important thing to us and we appreciate
    that many of you will be traveling a long distance to be with us!`,
    my: `Walaupun begitu, kehadiran anda pada hari itu adalah perkara yang paling penting bagi kami dan
    kami menghargai bahawa ramai daripada anda akan menempuh perjalanan yang jauh untuk bersama
    kami!`,
  },
};
