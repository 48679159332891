import React, { useContext } from 'react';
import { styWrapper } from '../HelloSection/styles';
import ThankYouGif from '@assets/images/thank-you.gif';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';

function GiftListSection() {
  const { lang } = useContext(LangContext);
  return (
    <>
      <div id="fh5co-couple" className="fh5co-section-gray" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              {/* <h2 className="main-font">{translation['gift_list_title'][lang]}</h2> */}
              <p className="info">
                {translation['gift_list_info'][lang]}
                <br />
                <div
                  style={{
                    marginTop: '3rem',
                    marginBottom: '3rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img src={ThankYouGif} alt="Thank You" className="img-responsive" loading="lazy" />
                </div>
                <p className="sub-title">Wassalamualaikum warahmatullahi wabarakatuh.</p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(GiftListSection);
