import React, { useContext } from 'react';
import { styWrapper } from './styles';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';

function HelloSection() {
  const { lang } = useContext(LangContext);

  return (
    <>
      <div id="fh5co-couple" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">Assalamualaikum Wr. Wb</h2>
              <h3 className="sub-title hs">{translation['wedding_date'][lang]}</h3>
              <p className="info">{translation['info'][lang]}</p>
            </div>
          </div>
          <div className="couple-wrap">
            <div className="couple-half">
              <div className="groom">
                <img
                  src={`https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-001/107637-32.jpg?tr=w-500,h-500,fo-auto`}
                  alt="groom"
                  className="img-responsive"
                  loading="lazy"
                />
              </div>
              <div className="desc-groom">
                <h3 className="main-font">Syarifah Nadia</h3>
                <p className="parent-name parent-name__top">
                  {translation['desc_bride'][lang]} Bibi Bianah
                  <br /> & Ali Hassan
                </p>
              </div>
            </div>
            <p className="heart text-center">
              <i className="icon-heart2"></i>
            </p>
            <div className="and-love">
              <i>&</i>
            </div>
            <div className="couple-half">
              <div className="bride">
                <img
                  src={`https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-001/107637-51.jpg?tr=w-500,h-500,fo-auto`}
                  alt="groom"
                  className="img-responsive"
                  loading="lazy"
                />
              </div>
              <div className="desc-bride">
                <h3 className="main-font">Adri Shahri</h3>
                <p className="parent-name">
                  {translation['desc_groom'][lang]} Sediah Mohamed Yusope <br />& Santosoh Gariman
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HelloSection;
