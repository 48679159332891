import React from 'react';

function FooterSection() {
  // get current year
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer id="fh5co-footer" role="contentinfo">
        <div className="container">
          <div className="row copyright">
            <div className="col-md-12 text-center">
              <p>
                <small className="block">&copy; {currentYear} Futurify. All Rights Reserved.</small>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default React.memo(FooterSection);
