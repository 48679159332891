import FloatingMusic from '@components/FloatingMusic/Loadable';
import FooterSection from '@components/FooterSection';
import HelloSection from '@components/HelloSection';
import MainLayout from '@components/Layout';
import PhotoSection from '@components/PhotoSection/Loadable';
import StorySection from '@components/StorySection';
import WeddingSection from '@components/WeddingSection';
import WelcomeSection from '@components/WelcomeSection';
import { inject } from '@vercel/analytics';
import React, { useEffect, useState } from 'react';
import GiftListSection from '../components/GiftListSection';
import RSVPSection from '../components/RSVPSection';

function Home() {
  const [showDetailContent, setShowDetailContent] = useState(false);

  useEffect(() => {
    inject();
  }, []);

  const handleClickDetail = () => {
    setShowDetailContent(true);
  };

  const renderDetailContent = () => {
    if (!showDetailContent) return null;

    return (
      <>
        <HelloSection />
        <WeddingSection />
        <StorySection />
        <PhotoSection />
        <RSVPSection />
        <GiftListSection />
        <FooterSection />
      </>
    );
  };

  return (
    <MainLayout>
      <WelcomeSection onClickDetail={handleClickDetail} />
      {renderDetailContent()}
      <FloatingMusic />
    </MainLayout>
  );
}

export default Home;
