import { css } from '@emotion/core';

export const styWrapper = css`
  max-width: 100%;

  .main-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 18px);
    padding: 0 20px;
  }
`;

// make it sticky header called toggleLang on right side
export const navbar = css`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
`;
