import '@assets/css/bootstrap.css';
import '@assets/css/icomoon.css';
import '@assets/css/style.css';
import Favicon from '@assets/images/rexx-icon.png';
import { node } from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { navbar } from './styles';
import { useState, createContext } from 'react';

const IMAGE_URL = `https://ik.imagekit.io/ass77/drive-download-20230511T095234Z-002/107637-306.jpg`;
const META_DESCRIPTION = `The wedding of Nadia & Adri invitation website created with love by Futurify`;

export const LangContext = createContext();
function MainLayout({ children }) {
  // state for toggle language
  const [lang, setLang] = useState('en');

  // function for toggle language and switch betwwen en and my
  const toggleLang = () => {
    const lang = localStorage.getItem('lang');

    if (lang === 'en') {
      setLang('my');
      localStorage.setItem('lang', 'my');
    } else {
      setLang('en');
      localStorage.setItem('lang', 'en');
    }
  };

  const langContextValue = {
    lang,
    toggleLang,
  };

  return (
    <>
      <Helmet>
        <title>Nadia ❤️ Adri</title>

        {/* Favicon */}
        <link rel="icon" type="image/png" href={Favicon} />

        {/* font and SEO tags */}
        <meta property="og:title" content="The wedding of Nadia & Adri" />
        <meta property="og:image" content={IMAGE_URL} />
        <meta property="og:url" content="https://snas.wedding-knot.xyz" />
        <meta property="og:site_name" content="SNAS Wedding Knot" />
        <meta property="og:description" content={META_DESCRIPTION} />
        <meta name="twitter:title" content="The wedding of Nadia & Adri" />
        <meta name="twitter:description" content={META_DESCRIPTION} />
        <meta name="twitter:image" content={IMAGE_URL} />
        <meta name="twitter:url" content="https://snas.wedding-knot.xyz" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@0xadr7" />

        <link
          href="https://fonts.googleapis.com/css?family=Work+Sans:400,300,600,400italic,700"
          rel="stylesheet"
          type="text/css"
        />
        <link href="https://fonts.googleapis.com/css2?family=Cookie&display=swap" rel="stylesheet"></link>
      </Helmet>
      <div id="page">
        <div css={navbar}>
          <button
            className="btn btn-secondary btn-sm"
            style={{
              position: 'fixed',
              backgroundColor: 'transparent',
              border: '1px solid #f14e95',
              color: '#f14e95',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
            onClick={toggleLang}
          >
            <span className="icon-globe"></span>
            &nbsp;
            <span
              className="text-center"
              style={{
                fontSize: '14px',
              }}
            >
              {lang === 'en' ? 'Malaysia' : 'English'}
            </span>
          </button>
        </div>
        <LangContext.Provider value={langContextValue}>{children}</LangContext.Provider>
      </div>
    </>
  );
}

MainLayout.propTypes = {
  children: node.isRequired,
};

export default MainLayout;
