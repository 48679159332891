import React, { useContext } from 'react';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';

function FlowEvents() {
  const { lang } = useContext(LangContext);
  return (
    <div className="col-md-6 col-sm-6 text-center">
      <div className="event-wrap">
        <h3>{translation['flow_event_title'][lang]}</h3>
        <div className="event-col">
          <i className="icon-clock"></i>
          <span>1:00PM</span>
        </div>
        <div className="event-col">
          <li>{translation['flow_event_1'][lang]}</li>
        </div>
        <div className="event-col">
          <i className="icon-clock"></i>
          <span>2:00PM</span>
        </div>
        <div className="event-col">
          <li>{translation['flow_event_2'][lang]}</li>
        </div>
        <div className="event-col">
          <i className="icon-clock"></i>
          <span>2:30PM</span>
        </div>
        <div className="event-col">
          <li>{translation['flow_event_3'][lang]}</li>
        </div>
        <div className="event-col">
          <i className="icon-clock"></i>
          <span>3:00PM</span>
        </div>
        <div className="event-col">
          <li>{translation['flow_event_4'][lang]}</li>
        </div>
        <div className="event-col">
          <i className="icon-clock"></i>
          <span>5:00PM</span>
        </div>
        <div className="event-col">
          <li>{translation['flow_event_5'][lang]}</li>
        </div>
      </div>
    </div>
  );
}

export default React.memo(FlowEvents);
