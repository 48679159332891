import React, { useContext } from 'react';
// import { useForm } from 'react-hook-form';
import { styWrapper } from '../HelloSection/styles';
import { LangContext } from '../Layout';
import { translation } from '../../constants/translation';

const SNAS_GOOGLE_FORM_RSVP = 'https://forms.gle/seYEw3wQxwPP56QK9';

function RSVPSection() {
  const { lang } = useContext(LangContext);
  return (
    <>
      <div id="fh5co-couple" className="fh5c0-section-pink" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">{translation['rsvp_title'][lang]}</h2>
              <div>
                <p className="info">{translation['rsvp_intro'][lang]},</p>

                <div>
                  <p className="info">{translation['rsvp_info_1'][lang]}</p>
                  {/* <p className="info">{translation['rsvp_info_2'][lang]}</p> */}
                </div>

                <div>
                  <p className="info">{translation['rsvp_info_4'][lang]}</p>
                </div>

                <div>
                  <p className="info">{translation['regards'][lang]},</p>

                  <p className="main-font">Mr. & Mrs. SNAS</p>
                </div>
              </div>

              <p className="info">{translation['rsvp_info_3'][lang]}</p>
              {/* <a
                href={SNAS_GOOGLE_FORM_RSVP}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
                style={{
                  animation: 'pulse 1.5s ease infinite',
                  marginTop: '25px',
                }}
              >
                {translation['rsvp_button'][lang]}
              </a> */}
              <div
                className="btn btn-primary"
                style={{
                  cursor: 'not-allowed',
                  animation: 'pulse 1.5s ease infinite',
                  marginTop: '25px',
                }}
              >
                {translation['rsvp_ended'][lang]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(RSVPSection);
